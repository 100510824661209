import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import ListGroup from "react-bootstrap/ListGroup"
import Row from "react-bootstrap/Row"
import Tab from "react-bootstrap/Tab"
import "../../styles/Details.css"

function MoreDetails(props) {
  return (
    <>
      <Container className="cTitle">
        <Row>
          <Col>
            <h2 class="description boldTitle containerTop headingSpacing overrideLeft left">
              More About RemoteTA Volunteering
            </h2>
          </Col>
        </Row>
      </Container>
      <Container className="tabSpacing">
        <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
          <Row>
            <Col sm={4}>
              <ListGroup>
                {props.tabs.map((value, index) => {
                  return (
                    <ListGroup.Item action href={"#" + index + 1}>
                      {value}
                    </ListGroup.Item>
                  )
                })}
              </ListGroup>
            </Col>
            <Col sm={8}>
              <Tab.Content>
                {props.descriptions.map((value, index) => {
                  return <Tab.Pane eventKey={"#" + index + 1}>{value}</Tab.Pane>
                })}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  )
}
export default MoreDetails

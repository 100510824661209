import React from "react"
import MoreDetails from "../components/homepage/details"
import Layout from "../components/utils/layout"
import dots from "../images/bgs/dots.svg"


const d1 = (
  <p>
    Upon signing up for an account, students can view all the tasks posted by
    teachers. If they find a task they are interested in, they can apply for it
    by clicking “Sign Up”. Upon signing up, students are prompted to list any
    experience they have with tasks similar to the one posted and are asked to
    explain why they want this task. Both of these responses are to be very
    brief and bullet form.
    <br></br>
    <br></br>
    Do not worry if you have no experience for a task, most of the time all
    teachers want to see is an interest for the task.
  </p>
)
const d2 = (
  <p>
    The number of volunteer hours awarded for completing a task is determined by
    both the student and the teacher. Along the course of the task, the student
    will have a volunteer hours log in their portal. Whenever the work on the
    task for an hour, they log the hour, and add a short description of what was
    accomplished.
    <br></br>
    <br></br>
    When the task is completed, teachers review the log. If the number of hours
    is reasonable to the teacher, they can approve the hours and student will
    then receive the hours. If the number of hours seems incorrect to the
    teacher, they can ask clarifying questions, or modify the number of hours.
  </p>
)
const d3 = (
  <p>
    If a teacher selects you for a task (usually within 0-3 days of you signing
    up) you can now begin volunteering!
    <br></br>
    <br></br>
    First, the teacher will email you the full details of the task, or schedule
    a phone call to discuss the task. Upon receiving and understanding the
    details, you can begin working on the task.
    <br></br>
    <br></br>
    Your contact with the teacher after that point fully depends on the teacher
    and your own needs. Teachers may send you an email asking for an update on
    your work, and you are expected to reply with an update (not replying within
    48 hours could hurt the number of volunteer hours you receive). On the other
    hand, if you have any questions, you can easily ask your teacher via the
    email button in the student portal.
  </p>
)
const d4 = (
  <p>
    If you have completed the task in time before the deadline, you are on your
    way to recovering volunteer hours!
    <br></br>
    <br></br>
    In the student portal, students can upload files containing their work, and
    submit to the teacher. Teachers then briefly review the work, review the
    volunteer hour logs, and finally grant you your volunteer hours.
    <br></br>
    <br></br>
    The number of hours you have can be found in the profile section. Completing
    tasks also adds to the number of tasks you have completed, a statistic that
    is available to teachers and strengthens your credibility as a RemoteTA
    volunteer.
  </p>
)

const d5 = (
  <p>
    Submitting work by the deadline is mandatory when volunteering with
    RemoteTA. Failure to do so results in the students not receiving their
    volunteer hours, as well as adds to the number of late tasks associated with
    their profile (a statistic available to teachers)
    <br></br>
    <br></br>
    However, if students communicate that they need an extension to the teacher
    (for a good reason), teachers can extend the deadline, allowing students to
    meet the deadline and receive their volunteer hours. As long students take
    initiative to actively communicate, meeting the deadline for every task is
    feasible.
  </p>
)

const MoreAboutRemoteTA = () => (
  <Layout image={dots}>
    <MoreDetails
      descriptions={[d1, d4, d3, d2, d5]}
      tabs={[
        "Signing Up For a Task",
        "Contact with Teachers",
        "Completing the Task",
        "Volunteer Hours",
        "Deadlines",
      ]}
    />
  </Layout>
)

export default MoreAboutRemoteTA
